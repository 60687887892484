/* @font-face {
    font-family: 'Gotham Greek';
    src: url('GothamGreek-Light.woff2') format('woff2'),
    url('GothamGreek-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham Greek';
    src: url('GothamGreek-Medium.woff2') format('woff2'),
    url('GothamGreek-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Gotham Greek';
    src: url('GothamGreek-Bold.woff2') format('woff2'),
    url('GothamGreek-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Light.woff2') format('woff2'),
    url('Avenir-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Oblique.woff2') format('woff2'),
    url('Avenir-Oblique.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Roman.woff2') format('woff2'),
    url('Avenir-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Medium.woff2') format('woff2'),
    url('Avenir-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-LightOblique.woff2') format('woff2'),
    url('Avenir-LightOblique.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-MediumOblique.woff2') format('woff2'),
    url('Avenir-MediumOblique.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Black.woff2') format('woff2'),
    url('Avenir-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-HeavyOblique.woff2') format('woff2'),
    url('Avenir-HeavyOblique.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('Avenir-Heavy.woff2') format('woff2'),
    url('Avenir-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
 */

 @font-face {
    font-family: 'Graphik';
    font-weight: normal;
    src: url('./Graphik-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-RegularItalic.otf') format('opentype');
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: 'Graphik';
    font-weight: 600;
    src: url('./Graphik-Semibold.otf') format('opentype');
}

@font-face {
    font-family: 'Graphik';
    src: url('./Graphik-SemiboldItalic.otf') format('opentype');
    font-style: italic;
    font-weight: 600;
}

@font-face {
    font-family: 'GraphikCondensed';
    font-weight: 600;
    src: url('./GraphikCondensed-Semibold.otf') format('opentype');
}

@font-face {
    font-family: 'GraphikCondensed';
    font-weight: 600;
    font-style: italic;
    src: url('./GraphikCondensed-SemiboldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'GraphikWide';
    font-weight: 700;
    src: url('./GraphikWide-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'GraphikWide';
    font-weight: 700;
    font-style: italic;
    src: url('./GraphikWide-BoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'GraphikXCondensed';
    font-weight: 700;
    src: url('./GraphikXCondensed-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'GraphikXCondensed';
    font-weight: 700;
    font-style: italic;
    src: url('./GraphikXCondensed-BoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'GraphikXXCondensed';
    font-weight: 700;
    src: url('./GraphikXXCondensed-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'GraphikXXCondensed';
    font-weight: 700;
    font-style: italic;
    src: url('./GraphikXXCondensed-BoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'GraphikXXXCondensed';
    font-weight: 700;
    src: url('./GraphikXXXCondensed-Bold.otf') format('opentype');
}

@font-face {
    font-family: 'GraphikXXXCondensed';
    font-weight: 700;
    font-style: italic;
    src: url('./GraphikXXXCondensed-BoldItalic.otf') format('opentype');
}