/* autoprefixer grid: autoplace */

:root {
    --fontFamilyBody: 'Graphik', sans-serif;
    --fontFamilyHeadings: 'Graphik', sans-serif;

    --tabx-primary-dark-green: #03260F;
    --tabx-game-bar-green: #222222;
    --tabx-primary-tab-green: #008542;
    --tabx-primary-tab-line-green: #25EC6C;
    --tabx-neutrals-off-black: #111111;
    --tabx-neutrals-grey: #727272;
    --tabx-neutrals-background-grey: #F2F2F2;
    --tabx-neutrals-light-grey: #EAF4E9;
    --tabx-neutrals-white: #FFFFFF;
    --tabx-neutrals-off-white: #FEFEFE;
    --tab-misc-valid: #3A972C;
    --tab-misc-invalid: #EB5C0E;
    --tabx-misc-invalid: #EB300E;
    --TAB-Masterbrand-Primary-TAB-Night-60: #004045;
    --TAB-Masterbrand-Primary-TAB-Green-60: #26E36B; 
    --TAB-Masterbrand-Primary-TAB-Wattle-60: #E1FF00;
    --TAB-Masterbrand-Secondary-TAB-Grey-80: #2B2E2B;
    --TAB-Masterbrand-Primary-TAB-Neon-60: #96FF03;
    --TAB-Input-Border: #BDC0BD;
    --TAB-Mobile-Item: #EAEEEA;
    --TAB-Masterbrand-Secondary-TAB-Night-80:#00282B;
    --TAB-Night-20:#EEF8E9;
    --TAB-Coming-Soon: #CBE500;
    --TAB-Masterbrand-Secondary-TAB-Grey-40: #EAEEEA;
}


html, body {
    font-family: var(--fontFamilyBody);
    min-height: 100%;
    height: 100%;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    -webkit-tap-highlight-color: transparent;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    /*padding: 10px;*/
}

button {
    cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
    font-size: 16px !important;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: rgba(234, 238, 234, 0.7);
}

/* Track */
::-webkit-scrollbar-track {
    width: 8px;
    border-radius: 1px;
    background: rgba(234, 238, 234, 0.7);
}

/* Handle */
::-webkit-scrollbar-thumb {
    margin-top: 4px;
    width: 80%;
    outline: 1px solid slategrey;
    border-radius: 4px;
    background: var(--TAB-Masterbrand-Secondary-TAB-Grey-60, #bdc0bd);
}
